import React, { useState } from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"

export function PostTitle({ title, detailsPageSubtitle, listDescription, url }) {
  const [copied, setCopied] = useState(false)

  const copy = () => {
    navigator.clipboard.writeText(url)
    setCopied(true)
  }

  return (
    <div className="paper-header">
      <h1 className="title">{title}</h1>
      <div
        className="sub-title"
        dangerouslySetInnerHTML={{
          __html: detailsPageSubtitle || listDescription,
        }}
      ></div>
      <div className="paper-share">
        <div className="social-icons">
          <FacebookShareButton url={url}>
            <button
              className="btn-social btn-facebook"
              type="button"
              title="Share on Facebook"
            >
              <span className="icon-facebook"></span>
            </button>
          </FacebookShareButton>
          <LinkedinShareButton url={url}>
            <button
              className="btn-social btn-linkedin"
              type="button"
              title="Share on Linkedin"
            >
              <span className="icon-linkedin"></span>
            </button>
          </LinkedinShareButton>
          <TwitterShareButton url={url}>
            <button
              className="btn-social btn-twitter"
              type="button"
              title="Share on Twitter"
            >
              <span className="icon-twitter"></span>
            </button>
          </TwitterShareButton>
          <button
            className="btn-social btn-copy-link text-copied"
            type="button"
            title="Share Link"
            onClick={copy}
          >
            {copied ? (
              <span className="btn-copied-text">
                Link
                <br /> Copied!
              </span>
            ) : null}
          </button>
        </div>
      </div>
    </div>
  )
}
