import $ from "jquery"
import TransitionLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React, { useRef } from "react"

export const DepartmentCard = ({
  slug,
  name,
  description,
  isArticle,
  details_sections: { sections },
  verticalAnimation,
}) => {
  const { backgroundImage } = sections ? sections[0] : {}
  const { heroBackgroundImage } = sections ? sections[0] : {}
  const { publicURL } = backgroundImage?.localFile || {}
  const { publicURL: backgroundPublicUrl } = heroBackgroundImage?.localFile || {}
  const cardImgRef = useRef();
  const cardRef = useRef();
  const copy = useRef();


  return (
    <div className={`thumb-slide ${isArticle ? "is--article-card" : ""}`} ref={cardRef}>
      <div className="thumb-slide__inner" style={{ backgroundImage: `url(${publicURL})` }} >
        <div className={`cardImg ${!isArticle ? "wide" : ""}`} ref={cardImgRef}>
          <img src={backgroundPublicUrl}  />
        </div>
        <div className="thumb-slide__content">
          <div className="copy " ref={copy}>
            <h3
              className="title"
              dangerouslySetInnerHTML={{
                __html: name,
              }}
            ></h3>
                        <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></div>
            <AniLink
              to={`/${slug}`}
              cover
              bg="#1aafe5"
              className="link"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="142" height="36" x="0" y="0">
                <path d="M 72 36 H 19 C 12 36 0 32 0 18 v 0 C 0 6 11.2 0 19 0 h 103 c 14 0 20 8 20 19 v 0 C 142 29 133 36 122 36 z" className="path" fill="transparent" stroke="#ffffff" />
              </svg>
              <span>Read More</span>
            </AniLink>
          </div>
        </div>
      </div>
    </div>
  )
}
