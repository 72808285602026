import React, { Fragment } from "react"

function PostIframeSection(props) {
  const { iframeSectionLink, iframeHeight, iframeMobileHeight, iframeSectionDescription } = props
  return (
    <Fragment>
      <div className="webView">
        <iframe
          src={iframeSectionLink}
          title="iframe Example 1"
          width="640"
          height={iframeHeight}
          frameBorder="0"
          className="iframe-container"
          scrolling="no"
        ></iframe>
      </div>
      <div className="mobileView">
        <iframe
          src={iframeSectionLink}
          title="iframe Example 1"
          width="640"
          height={iframeMobileHeight}
          frameBorder="0"
          className="iframe-container"
          scrolling="no"
        ></iframe>
      </div>
      <div className="caption">{iframeSectionDescription}</div>
    </Fragment>
  )
}
export default PostIframeSection
