import React from "react"
import { CreateParagraph } from "../../utils/create.htmlElement"
import { quoteSetting } from "../../utils/quote.settings"
export function PostTextQuote({
  quote,
  showTextQuoteMark,
  textQuotePosition,
  quoteParagraph,
}) {
  const positionClass = {
    left: "quote-left",
    right: "quote-right",
  }

  return (
    <section className={`copy-quote ${positionClass[textQuotePosition]}`}>
      <div className="copy">
        {(quoteParagraph || []).map((d, index) => {
          return <CreateParagraph key={index} content={d.quoteParagraph} />
        })}
      </div>
      {!!quote && (
        <blockquote className={quoteSetting(showTextQuoteMark)}>
          {/* {quote} */}
          <div dangerouslySetInnerHTML = {{ __html:quote}}></div>
        </blockquote>
      )}
    </section>
  )
}
