import $ from "jquery"
import React, { useEffect } from "react"
import { getGifPath } from "../../utils/media.library"
export const VideoCard = ({ videoUrl, videoSliderList, mobileVideoUrl }) => {
  useEffect(() => {
    if (typeof window !== undefined) {
      const nestedSlider = $(".nested-slider")

      nestedSlider.not(".slick-initialized").slick({
        dots: true,
        infinite: false,
        speed: 550,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        adaptiveHeight: false,
        variableWidth: false,
        arrows: false,
        swipeToSlide: false,
        appendDots: ".nested-controls",
        cssEase: "linear",
        fade: true,
        // vertical: true,
        // responsive: [
        //   {
        //     breakpoint: 768,
        //     settings: {
        //       vertical: false
        //     }
        //   }
        // ]
      })
    }
  }, [])

  const loadVideo = videoUrl => {
    if (!!videoUrl.includes(".mp4")) {
      let gifPath = getGifPath(videoUrl)
      return (
        <video
          preload="auto"
          playsInline
          autoPlay
          loop
          muted
          className="embed-responsive-item"
        >
          <source src={gifPath} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      )
    } else {
      return (
        <iframe
          title="big-video-card"
          className="embed-responsive-item"
          src={`${videoUrl}?controls=0&rel=0&showinfo=0&modestbranding=1&disablekb=1&color=white`}
          frameBorder="0"
          defer
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )
    }
  }

  return (
    <div className="thumb-slide xxl-slide carousel-card">
      <div className="thumb-slide__inner">
        <div className="thumb-slide__content">
          <div className="copy">
            <div className="nested-slider">
              {(videoSliderList || []).map((slider, index) => {
                const { publicURL: iconLink } =
                  slider?.sliderIcon?.localFile || {}
                return (
                  <div className="copy-slider-item" key={index}>
                    <h3 className="title">
                      <em
                        dangerouslySetInnerHTML={{
                          __html: slider.sliderNumber,
                        }}
                      ></em>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: slider.sliderTitle,
                        }}
                      ></span>
                      <small
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: slider.sliderDescription,
                        }}
                      ></small>
                    </h3>
                    {iconLink && (
                      <div className="item-img">
                        <img src={iconLink} alt="slide icon" />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="media">
            <div className="video-player embed-responsive desktop-embed">
              {!!videoUrl ? loadVideo(videoUrl) : null}
            </div>
            <div className="video-player embed-responsive mobile-embed">
              {!!mobileVideoUrl ? loadVideo(mobileVideoUrl) : null}
            </div>
          </div>
          <div className="nested-controls"></div>
        </div>
      </div>
    </div>
  )
}
