import React from "react"

export function PostMeetDoctor({ meetDoctorsList }) {
  if (!meetDoctorsList.length) {
    return null
  }
  return (
    <section className="meet-doctors">
      <div className="meet-doctors__inner">
        {(meetDoctorsList || []).map((doctor, index) => {
          const { publicURL } = doctor?.meetDoctorsImage?.localFile || {}
          return (
            <div className="doctor-item" key={index}>
              <div className="media">
                <img src={publicURL} alt={doctor?.meetDoctorsTitle} />
              </div>
              <div className="copy">
                <div className="title">{doctor?.meetDoctorsTitle}</div>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: doctor?.meetDoctorsDescription,
                  }}
                ></div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
