import React from "react"
import { CreateParagraph } from "../../utils/create.htmlElement"
import { getGifPath } from "../../utils/media.library"
// TODO: @sahil@chirag no option for the auto play and mute configuration

export function PostTextImage({
  textImageBlackHeader,
  textImageBlueHeader,
  textImageCaption,
  textImageImagePosition,
  textImageVideoUrlOption,
  textImageParagraph,
  textImageImage,
}) {
  const { publicURL } = textImageImage?.localFile || {}
  const positionClass = {
    left: "media-left",
    right: "media-right",
  }
  const gifPath = getGifPath(textImageVideoUrlOption)
  return (
    <section
      className={`media-copy media-side ${positionClass[textImageImagePosition]}`}
    >
      <figure className="media">
        {!!textImageVideoUrlOption && !!gifPath ? (
          <video
            preload="auto"
            playsInline
            autoPlay
            loop
            muted
            className="embed-responsive-item"
          >
            <source src={gifPath} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        ) : (
          <img
            src={publicURL}
            className="img-fluid mx-auto d-block"
            alt="text-img media"
          />
        )}
        <figcaption>{textImageCaption}</figcaption>
      </figure>

      <div className="copy">
        {!!textImageBlackHeader && (
          <div className="date">{textImageBlackHeader}</div>
        )}
        {!!textImageBlueHeader && (
          <h3 className="copy-title">{textImageBlueHeader}</h3>
        )}
        {(textImageParagraph || []).map((d, index) => {
          return <CreateParagraph key={index} content={d.paragraph} />
        })}
      </div>
    </section>
  )
}
