import React from "react"
import { getGifPath } from "../../utils/media.library"
// TODO: @sahil handle the mute and autoplay here!
export function PostFullWidthMedia({
  fullWidthMediaCaption,
  fullWidthMediaVideoAutoplay,
  fullWidthMediaVideoMute,
  fullWidthMediaVideoUrl,
  fullWidthMediaImage,
  fullWidthMediaVideoUrlOption,
}) {
  if (!fullWidthMediaImage && !fullWidthMediaVideoUrlOption) {
    return null
  }
  if (!fullWidthMediaVideoUrlOption) {
    const { publicURL } = fullWidthMediaImage?.localFile || {}

    return (
      <figure className="media media-single">
        <img
          src={publicURL}
          className="img-fluid mx-auto d-block"
          alt="single media"
        />
        <figcaption>{fullWidthMediaCaption}</figcaption>
      </figure>
    )
  } else if (!!fullWidthMediaVideoUrlOption?.includes(".mp4")) {
    let gifPath = getGifPath(fullWidthMediaVideoUrlOption)
    return (
      <div className="paper-video">
        <div className="embed-responsive embed-responsive-16by9">
          <video
            preload="auto"
            autoPlay
            playsInline
            loop
            muted
            className="embed-responsive-item"
          >
            <source src={gifPath} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
        <div className="caption">{fullWidthMediaCaption}</div>
      </div>
    )
  }
  return (
    <div className="paper-video">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          title="youtube hss channel"
          className="embed-responsive-item"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${fullWidthMediaVideoUrlOption}?controls=0&rel=0&showinfo=0&modestbranding=1&disablekb=1&color=white`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="caption">{fullWidthMediaCaption}</div>
    </div>
  )
}
