import React from "react"

export const PostSectionHeaderBlue = ({ sectionHeaderBlue }) => {
  return (
    <section className="primary-title">
      <div className="copy">
        <h3 className="copy-title">{sectionHeaderBlue}</h3>
      </div>
    </section>
  )
}
