import TransitionLink from "gatsby-plugin-transition-link"
import $ from "jquery"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React, { useRef, useState } from "react"
import { CreateParagraph } from "../../utils/create.htmlElement"
import "./PostCard.module.scss"
export const PostCard = ({ selectPost, isEmpty }) => {
  const {
    uri,
    title,
    post: { listDesciption, backgroundImage, heroBackgroundImage },
  } = selectPost || {}
  const { publicURL } = backgroundImage?.localFile || {}
  const backgroundImageURL = heroBackgroundImage?.sourceUrl
  const [growBg, setgrowBg] = useState("")
  const cardImgRef = useRef()
  const cardInnerRef = useRef()
  const cardRef = useRef()

  

  return (
    <div className={`thumb-slide ${growBg}`} ref={cardRef}>
      <div className="thumb-slide__inner" ref={cardInnerRef} style={{ backgroundImage: `url(${backgroundImageURL})` }}>
        <div className="cardImg" ref={cardImgRef}>
          <img src={publicURL} alt={backgroundImage?.title} />
          {/* <img src="/images/new-asset-1.png" alt="" /> */}
        </div>
        <div className="thumb-slide__content">
          <div className="copy">
            <h3 className="title">{title}</h3>
            <CreateParagraph className="text" content={listDesciption} />
          </div>
          <AniLink
            to={uri}
            cover
            bg="#1aafe5"
            className="link"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="142" height="36" x="0" y="0">
              <path d="M 72 36 H 19 C 12 36 0 32 0 18 v 0 C 0 6 11.2 0 19 0 h 103 c 14 0 20 8 20 19 v 0 C 142 29 133 36 122 36 z" className="path" fill="transparent" stroke="#ffffff" />
            </svg>
            <span>Read More</span>
          </AniLink>
        </div>
      </div>
    </div>
  )
}
