import React from "react"
export function PostFooterCta({
  footerCtaImage,
  footerCtaLink,
  newTab,
  footerCtaBackground,
  footerCtaSubtitle,
  footerCtaTitle,
}) {
  const { publicURL } = footerCtaImage?.localFile || {}

  /* set link copy-dark on flag */

  return (
    <section
      className={`media-cta ${footerCtaBackground === "dark" ? "copy-dark" : ""
        }`}
    >
      <div className="copy">
        <div className="title">{footerCtaSubtitle}</div>
        <a
          href={footerCtaLink || "#"}
          className="link text-uppercase adarsh"
          target={newTab === "no" ? "_self" : "_blank"}
          rel="noreferrer"
          dangerouslySetInnerHTML={{
            __html: footerCtaTitle,
          }}
        ></a>
        {/* <AniLink
          target="_blank"
          className="link"
          to={footerCtaLink || "#"}
          dangerouslySetInnerHTML={{
            __html: footerCtaSubtitle,
          }}
        ></AniLink> */}
      </div>
      <figure className="media">
        <img src={publicURL} className="img-fluid" alt="prefooter" />
      </figure>
    </section>
  )
}
