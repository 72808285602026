import React from "react"
import { CreateSpan } from "../../utils/create.htmlElement"
export function PostIntroParagraph({
  introParagraph,
  introParagraphBoldedText,
}) {
  return (
    <section className="paper-intro">
      <p>
        <b className="emphasize">{introParagraphBoldedText}</b>{" "}
        <CreateSpan content={introParagraph} />
      </p>
    </section>
  )
}
