import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import gsap from "gsap"
import $ from "jquery"
import React, { useEffect } from "react"
import {
  Post2StatsAndText,
  PostDescription,
  PostFooter,
  PostFooterCta,
  PostFullBleedCarousel,
  PostFullWidthMedia,
  PostFullWidthQoute,
  PostImageQuoteShort,
  PostIntroParagraph,
  PostMeetDoctor,
  PostNav,
  PostSectionHeaderBlue,
  PostSectionListHeader,
  PostStatReferenceFaculty,
  PostTextBoxedQuote,
  PostTextImage,
  PostTextQuote,
  PostTitle,
} from "../../components"
import { Layout } from "../../components/Layout/Layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import useSiteMetadata from "../../utils/use-site-metadata"
import PostIframeSection from "../../components/PostIframeSection/PostIframeSection"
import { constants } from "../../constants/Global_Contants"
import PostBulletListSection from "../../components/PostBulletListSection/PostBulletListSection"

export default ({ data: { post }, entry, location }) => {
  const {
    name: categoryName,
    slug,
    categoryId,
    parent,
  } = post.categories.nodes[0]
  const { title, slug: postSlug } = post
  const {
    detailsPageSubtitle,
    listDesciption,
    articleOrder,
    backgroundImage,
    backgroundPosition,
    postBackOnCategory,
    heroBackgroundImage,
  } = post.post

  const { section } = post.post
  const introParagraphData = section.filter(
    x => x.__typename == "WpPost_Post_Section_IntroParagraph"
  )[0]
  const { introParagraphBoldedText, introParagraph } = introParagraphData
  const { mediaItemUrl } = post.featuredImage?.node || {}
  const { publicURL } = backgroundImage?.localFile || {}
  const { publicURL: backgroundPublicUrl } =
    heroBackgroundImage?.localFile || {}
  const transitionCoverRef = React.useRef()
  const layoutContentsRef = React.useRef()
  const heroPosition = {
    top: "align-hero-top",
    center: "align-hero-center",
    bottom: "align-hero-bottom",
  }

  const { siteUpdatedUrl } = useSiteMetadata()
  const OGTagDescription =
    detailsPageSubtitle && detailsPageSubtitle.length
      ? `${detailsPageSubtitle}`
      : introParagraphBoldedText &&
        introParagraphBoldedText.length &&
        introParagraph &&
        introParagraph.length
      ? `${introParagraphBoldedText} ${introParagraph}`
      : constants.SEOdescription;

  const toggleBodyClass = () => {
    if (typeof window !== undefined) {
      document.body.classList.add("article-loaded")
      if (!!document.body.classList.contains("page-loaded")) {
        document.body.classList.remove("page-loaded")
      } else {
        setTimeout(function () {
          document.body.classList.add("page-loaded")
        })
      }
    }
  }

  return (
    <>
      <GatsbySeo
        title={`${title}`}
        description={
          listDesciption && listDesciption.length
            ? `${listDesciption}`
            : constants.SEOdescription
        }
        canonical={`${process.env.WP_SITE_URL}${postSlug}`}
        openGraph={{
          url: `${process.env.WP_SITE_URL}${postSlug}`,
          title: ` ${title}`,
          description: `${OGTagDescription}`,
          site_name: `HSS | ${title}`,
          type: "article",
          images: [
            {
              url: `${mediaItemUrl}`,
            },
          ],
        }}
      />
      <Layout isArticle={true}>
        <main
          className={`landing articlePage landing-dept dept-po ${
            heroPosition[backgroundPosition]
          } ${!!entry?.state?.isFromHome ? "openFromHome" : ""}`}
          ref={layoutContentsRef}
          style={{ backgroundImage: `url(${backgroundPublicUrl})` }}
        >
          <div className="floating-card i-m-cover open">
            <div className="thumb-slide">
              <div className="thumb-slide__inner">
                <div className="cardImg">
                  <img src={publicURL} alt={title} />
                </div>
              </div>
              <article className="paper">
                <AniLink
                  to={`/${
                    !postBackOnCategory
                      ? ""
                      : !!parent
                      ? parent?.node?.slug
                      : slug
                  }`}
                  cover
                  bg="#1aafe5"
                  data-title="BACK TO<br>ALL<br>ARTICLES"
                  className="btn-icon btn-close btn-primary articleBackLink"
                >
                  <svg
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                    className="caret-left"
                  >
                    <path d="M11 4 L6 9 11 14 12 13 8 9 12 5 Z" />
                  </svg>
                </AniLink>
                <div className="paper__inner">
                  <PostNav
                    categoryId={categoryId}
                    articleOrder={articleOrder}
                    categoryName={categoryName}
                    toggleBodyClass={toggleBodyClass}
                  />

                  <div className="paper-content">
                    <PostTitle
                      url={`${siteUpdatedUrl}${location.pathname}`}
                      title={title}
                      detailsPageSubtitle={detailsPageSubtitle}
                      listDescription={listDesciption}
                    />

                    <div className="paper-body">
                      {(post.post.section || []).map((section, index) => {
                        switch (section.__typename) {
                          case "WpPost_Post_Section_IntroParagraph":
                            return (
                              <PostIntroParagraph
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_Description":
                            return (
                              <PostDescription
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_FullWidthQuote":
                            return (
                              <PostFullWidthQoute
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_ImageQuoteShort":
                            return (
                              <PostImageQuoteShort
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_2StatsAndText":
                            return (
                              <Post2StatsAndText
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_MeetDoctors":
                            return (
                              <PostMeetDoctor
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_SectionListHeader":
                            return (
                              <PostSectionListHeader
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_SectionHeaderBlue":
                            return (
                              <PostSectionHeaderBlue
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_FooterCta":
                            return (
                              <PostFooterCta
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_FullBleedCarousel":
                            return (
                              <PostFullBleedCarousel
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_StatReferenceFaculty":
                            return (
                              <PostStatReferenceFaculty
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          //Sahil: NEW REDESIGN CARDS
                          case "WpPost_Post_Section_TextImage":
                            return (
                              <PostTextImage
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_TextQuote":
                            return (
                              <PostTextQuote
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_TextBoxedQuote":
                            return (
                              <PostTextBoxedQuote
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_FullWidthMedia":
                            return (
                              <PostFullWidthMedia
                                {...section}
                                key={`${section.__typename}-${index}`}
                              />
                            )
                          case "WpPost_Post_Section_IframeSection":
                            return (
                              <div className="iframe-embed">
                                <PostIframeSection
                                  {...section}
                                  key={`${section.__typename}-${index}`}
                                />
                              </div>
                            )
                          case "WpPost_Post_Section_BulletList":
                            return (
                              <div>
                                <PostBulletListSection
                                  {...section}
                                  key={`${section.__typename}-${index}`}
                                />
                              </div>
                            )
                          default:
                            return null
                        }
                      })}
                    </div>
                    <PostFooter></PostFooter>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </main>
        <TransitionPortal>
          <div
            ref={transitionCoverRef}
            className="curtain-loader"
            style={{
              position: "fixed",
              background: "#1aafe5",
              top: 0,
              right: 0,
              width: "100%",
              height: "100vh",
              transform: "translateY(100%)",
            }}
          />
        </TransitionPortal>
      </Layout>
    </>
  )
}

export const query = graphql`
  query post($id: String!) {
    post: wpPost(id: { eq: $id }) {
      title
      status
      slug
      link
      id
      categories {
        nodes {
          name
          count
          slug
          categoryId: id
          parent: wpParent {
            node {
              id
              name
              slug
            }
          }
        }
      }
      slug
      featuredImage {
        node {
          sourceUrl
          mediaItemUrl
          id
        }
      }
      post {
        articleOrder
        detailsPageSubtitle
        listDesciption
        backgroundPosition
        postBackOnCategory
        section {
          ...postIntroParagraph
          ...postDescription
          ...postSectionHeaderBlue
          ...postSectionHeaderList
          ...postTextImage
          ...postTextQuote
          ...postTextBoxedQuote
          ...postImageQuoteShort
          ...postImageMediumQuote
          ...postFullWidthQoute
          ...postFullWidthMedia
          ...postFullBleedCarousel
          ...post2StatsAndText
          ...postStatReferenceFaculty
          ...postFooterCta
          ...postMeetDoctors
          ...postIframeSection
          ...postBulletListSection
        }
        fieldGroupName
        backgroundImage {
          altText
          localFile {
            publicURL
          }
        }
        heroBackgroundImage {
          altText
          localFile {
            publicURL
          }
        }
      }
    }
  }
  fragment postIntroParagraph on WpPost_Post_Section_IntroParagraph {
    fieldGroupName
    introParagraph
    introParagraphBoldedText
  }
  fragment postDescription on WpPost_Post_Section_Description {
    description
    fieldGroupName
  }
  fragment postSectionHeaderBlue on WpPost_Post_Section_SectionHeaderBlue {
    fieldGroupName
    sectionHeaderBlue
  }
  fragment postSectionHeaderList on WpPost_Post_Section_SectionListHeader {
    sectionListHeader
    sectionListNumber
  }
  fragment postTextImage on WpPost_Post_Section_TextImage {
    fieldGroupName
    textImageBlackHeader
    textImageBlueHeader
    textImageCaption
    textImageImagePosition
    textImageVideoUrl
    textImageVideoUrlOption
    textImageParagraph {
      paragraph
    }
    textImageImage {
      localFile {
        publicURL
      }
    }
  }
  fragment postTextQuote on WpPost_Post_Section_TextQuote {
    fieldGroupName
    quote
    showTextQuoteMark
    textQuotePosition
    quoteParagraph {
      quoteParagraph
      fieldGroupName
    }
  }
  fragment postTextBoxedQuote on WpPost_Post_Section_TextBoxedQuote {
    boxedQuote
    boxedQuoteMarkShow
    boxedQuotePosition
    fieldGroupName
    boxedQuoteParagraph {
      fieldGroupName
      boxedQuoteParagraph
    }
  }

  fragment postImageQuoteShort on WpPost_Post_Section_ImageQuoteShort {
    fieldGroupName
    quoteShortTitle
    quoteShortQuotePosition
    quoteShortImageCaption
    quoteShortQuoteShow
    quoteShortImage {
      localFile {
        publicURL
      }
    }
  }
  fragment postImageMediumQuote on WpPost_Post_Section_ImageMediumQuote {
    fieldGroupName
    quoteMediumImageCaption
    quoteMediumQuotePosition
    quoteMediumQuoteShow
    quoteMediumTitle
    quoteMediumImage {
      localFile {
        publicURL
      }
    }
  }
  fragment postFullWidthQoute on WpPost_Post_Section_FullWidthQuote {
    fieldGroupName
    fullWidthQuote
    fullWidthQuoteShow
  }
  fragment postFullWidthMedia on WpPost_Post_Section_FullWidthMedia {
    fieldGroupName
    fullWidthMediaCaption
    fullWidthMediaVideoAutoplay
    fullWidthMediaVideoMute
    fullWidthMediaVideoUrl
    fullWidthMediaVideoUrlOption
    fullWidthMediaImage {
      localFile {
        publicURL
      }
      title
    }
  }
  fragment postFullBleedCarousel on WpPost_Post_Section_FullBleedCarousel {
    fieldGroupName
    fullBleedCarouselSlider {
      fieldGroupName
      fullBleedCarouselImage {
        localFile {
          publicURL
        }
      }
      fullBleedCarouselTitle
    }
  }
  fragment post2StatsAndText on WpPost_Post_Section_2StatsAndText {
    fieldGroupName
    statAndTextTitle2
    statAndTextDescription2 {
      description
    }
    statsAndTextPercentageList2 {
      fieldGroupName
      percentageSubTitle
      percentageTitle
      unit
    }
    statPosition2
  }
  fragment postStatReferenceFaculty on WpPost_Post_Section_StatReferenceFaculty {
    fieldGroupName
    statReferenceFacultyList {
      facultyDescription
      facultyName
      facultyRole
      fieldGroupName
    }
    statReferenceFacultyTitle
    statReferenceFacultyList2 {
      statReferenceHelpText
      statReferenceLable
      statReferenceValue
      fieldGroupName
    }
  }
  fragment postFooterCta on WpPost_Post_Section_FooterCta {
    fieldGroupName
    footerCtaLink
    newTab
    footerCtaSubtitle
    footerCtaTitle
    footerCtaBackground
    footerCtaImage {
      altText
      localFile {
        publicURL
      }
    }
  }
  fragment postMeetDoctors on WpPost_Post_Section_MeetDoctors {
    fieldGroupName
    meetDoctorsList {
      fieldGroupName
      meetDoctorsDescription
      meetDoctorsTitle
      meetDoctorsImage {
        uri
        localFile {
          publicURL
        }
      }
    }
  }
  fragment postIframeSection on WpPost_Post_Section_IframeSection {
    fieldGroupName
    iframeSectionLink
    iframeHeight
    iframeMobileHeight
    iframeSectionDescription
  }
  fragment postBulletListSection on WpPost_Post_Section_BulletList {
    fieldGroupName
    bulletList {
      description
    }
  }
`
