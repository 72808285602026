import React, { useEffect } from "react"
import $ from "jquery"
export function PostFullBleedCarousel({ fullBleedCarouselSlider }) {
  useEffect(() => {
    if (typeof window !== undefined) {
      const cardSlider = $(".article-slider__inner")

      setTimeout(function () {
        cardSlider.not(".slick-initialized").slick({
          dots: true,
          infinite: false,
          speed: 550,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          adaptiveHeight: true,
          variableWidth: true,
          arrows: false,
          swipeToSlide: true,
          cssEase: "ease",
          centerPadding: "0",
          centerMode: true,
          touchThreshold: 100,
        })
      })
      // TODO: Added tushar ka code only for desktop bleed carousel
      if (window.screen.width > 1200) {
        $(".paper-content").hover(function () {
          $("body").removeClass("stopScroll")
        })

        $(".article-slider").hover(
          function () {
            $("body").addClass("stopScroll")
          },
          function () {
            $("body").removeClass("stopScroll")
          }
        )
        $(".article-slider__inner").on("wheel", function (e) {
          if (
            $(".article-slider .slick-slide:last-child").hasClass(
              "slick-current"
            )
          ) {
            $("body").removeClass("stopScroll")
          } else {
            $("body").addClass("stopScroll")
          }
          if (e.originalEvent.deltaY < 0) {
            cardSlider.slick("slickPrev")
            if (
              $(".article-slider .slick-slide:first-child").hasClass(
                "slick-current"
              )
            ) {
              $("body").removeClass("stopScroll")
            }
          } else {
            cardSlider.slick("slickNext")
          }
        })
      }
    }
  }, [])

  return (
    <section className="article-slider">
      <div className="article-slider__inner">
        {(fullBleedCarouselSlider || []).map((slide, index) => {
          const { publicURL } = slide?.fullBleedCarouselImage?.localFile
          return (
            <div className="article-slider-item" key={index}>
              <div className="media">
                <img src={publicURL} alt="" />
              </div>
              <div className="copy">
                <div className="caption">{slide.fullBleedCarouselTitle}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="article-slider__range">
        <div className="range">
          <div className="handle"></div>
        </div>
      </div>
    </section>
  )
}
