import React from "react"

function PostBulletListSection(props) {
  const { bulletList } = props
  return (
    <ul className="bullet">
      {(bulletList || []).map((d, index) => {
        return <li>{d.description}</li>
      })}
    </ul>
  )
}
export default PostBulletListSection
