import $ from "jquery"
import React, { useEffect } from "react"
import { WheelIndicator } from "../../assets/js/wheel-indicator.js"
import { PostCard } from "../PostCard/PostCard"
import { VideoCard } from "../VideoCard/VideoCard"
import { DepartmentXXLListingCard } from "../DepartmentXXLListingCard/DepartmentXXLListingCard"
import { CreateParagraph, CreateSpan } from "../../utils/create.htmlElement.js"
import { getGifPath } from "../../utils/media.library.js"

const RenderEmptySlide = () => {
  if (typeof window !== "undefined") {
    if (window && window.screen.width < 1025) return null
  }
  return <div className="thumb-slide"></div>
}

export default function ({
  description,
  landingTitle1,
  landingTitle2,
  landingYear,
  mainTitle,
  backgroundImage,
  cardsList,
  backgroundVideoOption,
}) {
  useEffect(() => {
    if (typeof window !== undefined) {

      const cardSlider = $("#thumb-slider")

      const prevBtn = $(".slick-prev");
      const nextBtn = $(".slick-next");

      cardSlider.on("click", nextBtn, function () {
        cardSequence()
      });

      cardSlider.on("click", prevBtn, function () {
        cardSequence()
      });

      function cardSequence() {
        $(".thumb-slide").each(function () {
          $(this).removeAttr("data-id")
        })

        $(".slick-current").attr("data-id", "1")
        $(".slick-current").next(".thumb-slide").attr("data-id", "2")
        $('.thumb-slide[data-id="2"]')
          .next(".thumb-slide")
          .attr("data-id", "3")
        $('.thumb-slide[data-id="3"]')
          .next(".thumb-slide")
          .attr("data-id", "4")
      }

      cardSlider.not(".slick-initialized").slick({
        dots: true,
        infinite: false,
        speed: 550,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        adaptiveHeight: true,
        variableWidth: true,
        arrows: true,
        prevArrow: '<button type="button" data-title="PREVIOUS ARTICLE" class="slick-prev"><svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" class="caret-left"><path d="M11 4 L6 9 11 14 12 13 8 9 12 5 Z"></path></svg></button>',
        nextArrow: '<button type="button" data-title="NEXT <br/> ARTICLE" class="slick-next"><svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" class="caret-right"><path d="M7 4 L12 9 7 14 6 13 10 9 6 5 Z"></path></svg></button>',
        swipeToSlide: true,
        cssEase: "linear",
        touchThreshold: 100,
        centerPadding: "0",
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      })
    }
  }, [])

  const { publicURL } = backgroundImage?.localFile || {}
  const videoUrl = getGifPath(backgroundVideoOption)

  return (
    <section
      className="landing-section bg-media"
      style={{
        backgroundImage: `url(${publicURL})`,
      }}
    >
      {videoUrl ? (
        <div className="bg-video">
          <video width="100%" height="100vh" preload="auto" autoPlay loop muted playsInline>
            <source src={videoUrl} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      ) : null}
      <div className="container-inner smoothenItUp">
        <div className="landing-copy">
          <div className="landing-title">
            <div className="label">
              {landingTitle1}
              <CreateSpan className="mobile-year" content={landingYear} />
              <CreateSpan className="tag" content={landingTitle2} />
            </div>
            <div className="year">{landingYear}</div>
          </div>
          <div className="landing-header">
            <h1 className="title">
              <CreateSpan className="lead" content={mainTitle} />
            </h1>
            <CreateParagraph className="text" content={description} />
          </div>
        </div>
        <div className="card-slider dept-slider" id="thumb-slider">
          {([...cardsList, { cardType: "blank_card" }] || []).map(
            (card, index) => {
              if (card.cardType === "blank_card") {
                return <RenderEmptySlide key={`${card.cardType}-${index}`} />
              } else if (card.cardType === "post_card" && !!card.selectPost) {
                return <PostCard key={`${card.cardType}-${index}`} {...card} />
              } else if (card.cardType === "video_card") {
                return <VideoCard key={`${card.cardType}-${index}`} {...card} />
              } else if (card.cardType === "big_card") {
                return (
                  <DepartmentXXLListingCard
                    key={`${card.cardType}-${index}`}
                    {...card}
                  />
                )
              }
              return null
            }
          )}
        </div>
      </div>
    </section>
  )
}
