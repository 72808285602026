import { graphql, useStaticQuery } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import React from "react"
import $ from "jquery"

function findUnique(arr, predicate) {
  var found = {}
  arr.forEach(d => {
    found[predicate(d)] = d
  })
  return Object.keys(found).map(key => found[key])
}

export const PostNav = ({
  categoryId,
  articleOrder,
  categoryName,
  toggleBodyClass,
}) => {
  const {
    allWpPost: { nodes },
  } = useStaticQuery(graphql`
    query postList {
      allWpPost {
        nodes {
          title
          slug
          link
          id
          categories {
            nodes {
              categoryId: id
            }
          }
          post {
            articleOrder
          }
        }
      }
    }
  `)

  const categoryPostList = (nodes || [])
    .map(node => {
      let isValidCategory = (node?.categories?.nodes || []).filter(category => {
        return category.categoryId === categoryId
      })
      if (!!isValidCategory.length) {
        return node
      }
      return null
    })
    .filter(Boolean)

  const postList = (categoryPostList || [])
    .map(node => {
      if (!node?.post?.articleOrder) {
        return null
      }
      return {
        ...node,
        articleId: node?.post?.articleOrder,
      }
    })
    .filter(Boolean)

  let curratedPostList = findUnique(postList, d => d.articleId)
  let nextPostOrder =
    articleOrder === curratedPostList.length ? 1 : articleOrder + 1
  let prevPostOrder =
    articleOrder === 1 ? curratedPostList.length : articleOrder - 1

  let prevPost = curratedPostList.filter(
    node => node?.post?.articleOrder === prevPostOrder
  )
  let nextPost = curratedPostList.filter(
    node => node?.post?.articleOrder === nextPostOrder
  )

  return (
    <div className="paper-nav">
      <div className="copy">
        <div className="title">{categoryName}</div>
        <div className="numbering">
          <span>{articleOrder}</span>
          <span>{curratedPostList?.length}</span>
        </div>
      </div>
      <div className="controls">
        <TransitionLink
          to={prevPost[0]?.link || "#"}
          exit={{
            length: 1,
            trigger: ({ exit }) => toggleBodyClass(),
          }}
          entry={{
            delay: 0.5,
            length: 1,
            state: {
              isOpen: true,
            },
            trigger: ({ entry, node }) => {
              toggleBodyClass()
              if (typeof window !== undefined) {
                $(this).scrollTop(0)
                document.body.classList.add("article-loaded")
              }
            },
          }}
          data-title="PREVIOUS<br>ARTICLE"
          className="btn-icon btn-controls btn-primary btn-prev"
        >
          <svg
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
            className="caret-left"
          >
            <path d="M11 4 L6 9 11 14 12 13 8 9 12 5 Z" />
          </svg>
        </TransitionLink>

        <TransitionLink
          to={nextPost[0]?.link}
          exit={{
            length: 1,
            trigger: ({ exit }) => toggleBodyClass(),
          }}
          entry={{
            delay: 0.5,
            length: 1,
            state: {
              isOpen: true,
            },
            trigger: ({ entry, node }) => {
              toggleBodyClass()
              if (typeof window !== undefined) {
                $(this).scrollTop(0)
                document.body.classList.add("article-loaded")
              }
            },
          }}
          data-title="NEXT<br>ARTICLE"
          className="btn-icon btn-controls btn-primary btn-next"
        >
          <svg
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
            className="caret-right"
          >
            <path d="M7 4 L12 9 7 14 6 13 10 9 6 5 Z" />
          </svg>
        </TransitionLink>
      </div>
    </div>
  )
}
