import React from "react"
import { quoteSetting } from "../../utils/quote.settings"

export function PostFullWidthQoute({ fullWidthQuote, fullWidthQuoteShow }) {
  if (!fullWidthQuote) {
    return null
  }
  return (
    <blockquote className={`block ${quoteSetting(fullWidthQuoteShow)}`}>
      {/* {fullWidthQuote} */}
      <div dangerouslySetInnerHTML = {{ __html:fullWidthQuote}}></div>
    </blockquote>
  )
}
