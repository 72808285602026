import React from "react"

export const PostSectionListHeader = ({
  sectionListHeader,
  sectionListNumber,
}) => {
  return (
    <div className="list-title">
      <span>{sectionListNumber}</span>
      {sectionListHeader}
    </div>
  )
}
