import React from "react"
import { quoteSetting } from "../../utils/quote.settings"
export function PostImageQuoteShort({
  quoteShortTitle,
  quoteShortImage,
  quoteShortImageCaption,
  quoteShortQuotePosition,
  quoteShortQuoteShow,
}) {
  const { publicURL } = quoteShortImage?.localFile || {}

  const position = {
    TopLeft: "medium-quote-left medium-quote-top",
    TopRight: "medium-quote-right medium-quote-top",
    BottomLeft: "",
    BottomRight: "medium-quote-right",
  }

  return (
    <section
      className={`media-quote media-parallax ${position[quoteShortQuotePosition]}`}
    >
      <figure className="media">
        <div class="imgWrapper">
          <img
            src={publicURL}
            className="d-block mx-auto img-fluid"
            alt="quote short"
          />
        </div>
        <figcaption>{quoteShortImageCaption}</figcaption>
      </figure>
      <blockquote className={quoteSetting(quoteShortQuoteShow)}>
      <div dangerouslySetInnerHTML = {{ __html:quoteShortTitle}}></div>
        {/* {quoteShortTitle} */}
      </blockquote>
    </section>
  )
}
