import HssFastFactGradient from "../../static/2021videos/B-Roll_551x310_desktopr_v02.mp4"
import HssFastFactGradientMobile from "../../static/2021videos/hss_fastfacts_gradient_mobile.mp4"
import PartA from "../../static/2021videos/article-videos/Peds Ortho/3dMD/3dMD technology-part-1.mp4"
import PartB from "../../static/2021videos/article-videos/Peds Ortho/3dMD/3dMD technology-part-2.mp4"
import AdultOrthoOsseointegrationInfographic from "../../static/2021videos/article-videos/Adult Ortho/Osseointegration/AdultOrtho_Osseointegration_Infographic_v06.mp4"
import RheumDisparitiesInfographic from "../../static/2021videos/article-videos/Adult Ortho/Disparities Knee/Rheum_Disparities_Infographic_v03.mp4"
import AdultOrthoBG from "../../static/2021videos/background-videos/BG_01_AdultOrtho_Landing_v02_OPT.mp4";
import PediaOrthoBG from "../../static/2021videos/background-videos/BG_02_PedsOrtho_Landing_v02_OPT.mp4";
import Rheum from "../../static/2021videos/background-videos/BG_03_Rheum_Landing_v02.mp4_OPT.mp4";
import WalkingBeachlady from "../../static/2021videos/article-videos/Adult Ortho/Osseointegration/Walking_beachlady_v01_OPT_v02.mp4";
import AdultOrthoLetterFromBK from "../../static/2021videos/article-videos/Adult Ortho/Letter from BK/AdultOrtho_Letter_video_v03_OPT.mp4"
import BRollArticleOPT from "../../static/2021videos/article-videos/Overarching Content/Letter from Leadership/B-Roll-Article_OPT.mp4"
import PedsTSFInfographic from "../../static/2021videos/article-videos/Peds Ortho/Tibial Spine Fractures/Peds_TSF_Infographic_v07.mp4"
import JIACarouselV_04 from "../../static/2021videos/article-videos/Rheum/Rheum_JIA_Carousel_v04.mp4"

export const getGifPath = key => {
  if (!key?.includes(".mp4")) {
    // For Youtube Id's
    return {
      type: "video",
      sources: [
        {
          src: key,
          provider: "youtube",
        },
      ],
    }
  } else {
    const label = key.split(".")[0]
    if (!gifVideoConfig[label]) {
      return null
    }
    return gifVideoConfig[label] || ""
  }
}

export const gifVideoConfig = {
  hss_fastfacts_gradient_mobile: HssFastFactGradientMobile,
  hss_fastfacts_gradient: HssFastFactGradient,
  Part_A_3dMD: PartA,
  Part_B_3dMD:PartB,
  AdultOrtho_Osseointegration_Infographic_v05:AdultOrthoOsseointegrationInfographic,
  Peds_TSF_Infographic_v06:PedsTSFInfographic,
  Rheum_Disparities_Infographic_v02:RheumDisparitiesInfographic,
  adulthortho_navy_bg:AdultOrthoBG,
  pediatricortho_navy_bg:PediaOrthoBG,
  rheum_sky_bg:Rheum,
  Walking_beachlady_v01:WalkingBeachlady,
  Adult_Ortho_LetterFromBK : AdultOrthoLetterFromBK,
  B_Roll_Article_OPT :BRollArticleOPT,
  Rheum_JIA_Carousel_v04 : JIACarouselV_04
}

/* 
Cytokine Storm : cytokine_storm.mp4
Motion track girl loop : motion_track_girl_loop.mp4
Pogo Stick Girl Loop : pogo_stick_girl_loop.mp4
Collaboration Doctors v1 : collaboration_doctors_v1.mp4
Complex Case knee v1 : complex_case_knee_v1.mp4
Complex case leg : complex_case_leg.mp4
Complex case spine  :complex_case_spine_v3.mp4
Covid map : covid_map_v1.mp4
Pro Sports : pro_sports_v1.mp4
Uncertain Diagnosis : uncertain_diagnosis.mp4
annual_report_fast_fact: AnnualReportFastFact
solving_prosthetic_joint_infections.mp4: Solving Prosthetic Join Infections,
predicting_ra_flares.mp4: Predicting Ra Flares,
hss_fastfacts_gradient.mp4: Hss Fast Facts Grandient,

*/
