import React from "react"
import { CreateParagraph } from "../../utils/create.htmlElement"
import { quoteSetting } from "../../utils/quote.settings"

export function PostTextBoxedQuote({
  boxedQuote,
  boxedQuoteMarkShow,
  boxedQuotePosition,
  boxedQuoteParagraph,
}) {
  const positionClass = {
    left: "quote-left",
    right: "quote-right",
  }

  return (
    <section
      className={`boxed-quote-copy ${positionClass[boxedQuotePosition]}`}
    >
      <div className="copy">
        {(boxedQuoteParagraph || []).map((d, index) => {
          return <CreateParagraph content={d.boxedQuoteParagraph} key={index} />
        })}
      </div>
      {!!boxedQuote && (
        <blockquote className={quoteSetting(boxedQuoteMarkShow)}>
          {/* {boxedQuote} */}
          <div dangerouslySetInnerHTML = {{ __html:boxedQuote}}></div>
        </blockquote>
      )}
    </section>
  )
}
