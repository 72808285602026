import React from "react"
// import TransitionLink from "gatsby-plugin-transition-link"
import hssLogo from "../../assets/images/hss-logotype.svg"
import { graphql, Link, useStaticQuery } from "gatsby"

// TODO: add adon menu
/* footer {
    footer {
      addonMenu {
        selectArticle {
          ... on Post {
            id
            title
            slug
          }
        }
        fieldGroupName
        selectDepartment {
          uri
          link
          name
          slug
        }
      }
    }
  } */

export function PostFooter({ verticalAnimation }) {
  const {
    allWp: { nodes },
  } = useStaticQuery(graphql`
    query {
      allWp {
        nodes {
          footer {
            pageTitle
            footer {
              fieldGroupName
              footerLeftSubtitle
              footerRightButtonLink
              footerRightButtonText
              footerRightSubtitle
              footerRightTitle
              footerTitle2
              footerTitle1
              footerLeftCopyWrite
              footerLinksLeftList {
                fieldGroupName
                link
                title
              }
              footerLinksRightList {
                fieldGroupName
                title
                link
              }
            }
          }
        }
      }
    }
  `)

  const { footer } = nodes[0].footer

  const {
    footerLeftSubtitle,
    footerRightButtonLink,
    footerRightButtonText,
    footerRightSubtitle,
    footerRightTitle,
    footerTitle2,
    footerLeftCopyWrite,
    footerLinksLeftList,
    footerLinksRightList,
  } = footer

  return (
    <div className="paper-footer">
      <div className="footer-title">
        <strong>
          <img src={hssLogo} alt="HSS logo" />
        </strong>
        <span>{footerTitle2}</span>
      </div>
      <div className="paper-footer__inner">
        <div className="footer-col fc-1">
          <div className="footer-meta">
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: footerLeftSubtitle,
              }}
            ></div>
          </div>
          <ul className="link-list text-uppercase">
            {([...footerLinksLeftList, ...footerLinksRightList] || []).map(
              (action, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={action.link}
                      // exit={{
                      //   length: 1,
                      //   trigger: ({ exit }) => verticalAnimation(exit, "up"),
                      // }}
                      // entry={{
                      //   delay: 0.5,
                      //   length: 1,
                      //   trigger: ({ entry, node }) => {
                      //     console.log("entry node comes here", node)
                      //   },
                      // }}
                      className="link"
                    >
                      {action.title}
                    </Link>
                  </li>
                )
              }
            )}
          </ul>
        </div>
        <div className="footer-col fc-2">
          <div className="footer-cta">
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: footerRightTitle,
              }}
            ></div>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: footerRightSubtitle,
              }}
            ></div>
            <a
              href={footerRightButtonLink}
              target="_blank"
              rel="noreferrer"
              className="link text-uppercase"
            >
              {footerRightButtonText}
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        {footerLeftCopyWrite || "&copy; 2020 Hospital for Special Surgery"}
      </div>
    </div>
  )
}
