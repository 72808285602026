import React from "react"
import { CreateParagraph, CreateSpan, CreateSpanWithSmall } from "../../utils/create.htmlElement"

export function Post2StatsAndText({
  statAndTextTitle2,
  statAndTextDescription2,
  statsAndTextPercentageList2,
  statPosition2,
}) {
  const position = {
    right: "stats-right",
    left: "",
  }

  return (
    <section className={`stats-copy ${position[statPosition2]}`}>
      <div className="copy">
        {(statAndTextDescription2 || []).map((d, index) => {
          return <CreateParagraph key={index} content={d.description} />
        })}
      </div>

      <div className="stats">
        <div className="stats__inner">
          <div className="title">{statAndTextTitle2}</div>
          {(statsAndTextPercentageList2 || []).map((percent, index) => {
            return (
              <div className="fact" key={index}>
                <CreateSpanWithSmall
                  className="number"
                  content={percent.percentageTitle}
                  smallClassName = "unit"
                  smallContent = {percent.unit}

                />
                <CreateSpan
                  className="text"
                  content={percent.percentageSubTitle}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
