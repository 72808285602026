import React from "react"
import { CreateParagraph, CreateSpan } from "../../utils/create.htmlElement"

export const PostStatReferenceFaculty = ({
  statReferenceFacultyList,
  statReferenceFacultyTitle,
  statReferenceFacultyList2,
}) => {
  return (
    <section className="stat-reference faculty-copy">
      <div className="copy">
        <h3 className="copy-title">{statReferenceFacultyTitle}</h3>
        {(statReferenceFacultyList || []).map((faculty, index) => {
          const { facultyDescription, facultyName, facultyRole } = faculty
          return (
            <div className="text" key={index}>
              <h4 className="faculty-role">{facultyRole} </h4>
              <CreateSpan className="faculty-name" content={facultyName} />
              <CreateParagraph content={facultyDescription} />
            </div>
          )
        })}

        {(statReferenceFacultyList2 || []).map((ref, index) => {
          const {
            statReferenceHelpText,
            statReferenceLable,
            statReferenceValue,
          } = ref
          return (
            <div className="text reference-item" key={index}>
              <div className="item">
                {statReferenceLable}{" "}
                <CreateSpan className="regular" content={statReferenceValue} />
              </div>
              <div
                className="help-text"
                dangerouslySetInnerHTML={{
                  __html: statReferenceHelpText,
                }}
              ></div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
