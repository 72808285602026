export function quoteSetting(params) {
  const quoteSetting = {
    no: "hide-quote",
    yes: "",
  }

  if (params === null) {
    return ""
  }
  return quoteSetting[params]
}
