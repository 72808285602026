import React from "react"
import { CreateParagraph } from "../../utils/create.htmlElement"

export const DepartmentXXLListingCard = ({
  bigCardTitle,
  bigCardImage,
  bigCardRightList,
  bigCardLeftList,
}) => {
  const { publicURL } = bigCardImage?.localFile || {}
  return (
    <div className="thumb-slide xxl-slide list-slide-card">
      <div className="thumb-slide__inner">
        <div className="thumb-slide__content">
          <div className="copy">
            <CreateParagraph className="title" content={bigCardTitle} />
            <div className="text">
              <ul className="marker-list">
                {(bigCardLeftList || []).map((listItem, index) => {
                  const { bigCardLeftListTitle, bigCardLeftListLink } = listItem
                  return (
                    <li key={`left-${index}`}>
                      <a href={bigCardLeftListLink} rel="noreferrer">
                        {bigCardLeftListTitle}
                      </a>
                    </li>
                  )
                })}
              </ul>
              <ul className="marker-list">
                {(bigCardRightList || []).map((listItem, index) => {
                  const {
                    bigCardLeftListTitle,
                    bigCardRightListLink,
                  } = listItem
                  return (
                    <li key={`right-${index}`}>
                      <a href={bigCardRightListLink} rel="noreferrer">
                        {bigCardLeftListTitle}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="media">
            <img src={publicURL} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
